import { Center } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/react'

import logo from '../../style/img/churrasqueira_logo.png'

import React from 'react'

const Header = () => {

  return (
    <Center w="100%" p={6} color="white" bg='#201c1c'>
        <Image src={logo} alt="ChurrasqueiraBBQ" w='100px' />
    </Center>
  )
}

export default Header