import React, { useEffect, useState } from 'react'

import {
  Center, FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useForm, Controller } from "react-hook-form";

import firebase from '../../firebase/firebaseConnection'
import Admin from '../Admin/Admin';

const Login = () => {

  const { control, handleSubmit } = useForm();
  const toast = useToast()

  const [user, setUser] = useState(false)

  useEffect(()=>{
    async function checkLogin(){
      await firebase.auth().onAuthStateChanged((user) => {
        if(user){
          setUser(true)
        } else{
          setUser(false)
        }
      })
    }

    checkLogin();
  }, [])

  const logout = async () => {
    await firebase.auth().signOut()
  }

  const onSubmit = async (data) => {
    // console.log(data)
    await firebase.auth().signInWithEmailAndPassword(data.email, data.password)
    .then(() => {
      toast({
        position:'bottom',
        title: "Sucesso.",
        description: "Login efetuado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    })
    .catch(()=> {
      toast({
        position:'bottom',
        title: "Erro",
        description: "Falha no login",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    })
  }

  return (<>
      {user ? <Admin logout={logout} /> :
    <Center bg='#201c1c' color='white' p='30px 0 25vh'>
      <VStack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl id="ADMIN">
            <FormLabel>E-mail</FormLabel>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => <Input type='email' {...field} required mb='5' />}
            />

            <FormLabel >Senha</FormLabel>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => <Input type='password' {...field} required mb='5' />}
            />

            <Input type="submit" mt='10px' />
          </FormControl>
        </form>
      </VStack>
    </Center>}
  </>);
}

export default Login;