import React from 'react';

import { Box, Image, Text, Flex, Link, Center } from "@chakra-ui/react"
import foodTray from '../../style/icon/food-tray.png'
import iApp from '../../style/img/appstoreBadge.png'
import iPlay from '../../style/img/playstoreBadge.png'

const Footer = () => {
  return (
    <Box w='100%'>
      <Flex direction='column'>
        <Flex direction='row' bg="rgb(238,41,58)" p='10px 5px' justify='space-around'>
          <Center w='25%'>
          <Image src={foodTray} w='100px' h='100px'/>
          </Center>
          <Flex direction='column' color='white' alignSelf='center' fontSize={['12px', '13px', '15px', '16px']}>
            
            <Text>DELIVERY pelo App</Text>
            <Text>Churrasqueira BBQ, Ifood, Robin Food</Text>
            <Text>e pelo tel.:32 3218-0707</Text>
           
          </Flex>
          <Flex direction='column' justify='space-evenly' w='30%' alignItems='center'>
            <Link href='https://apps.apple.com/id/app/churrasqueira-bbq-delivery/id1573961614' ><Image src={iApp}  maxH='40px' /></Link>
            <Link href='https://play.google.com/store/apps/details?id=churrasqueirabbq.delivery'><Image src={iPlay} maxH='40px'  /></Link>
          </Flex>
        </Flex>
        <Flex direction='column' bg="black" color='white' align='center' p='2vh 0' fontSize='12px'>
          <Text>
            Horário de funcionamento: de terça a sexta, das 11h às 22h
          </Text>
          <Text>
            Rua Dom Viçoso, Nº 111, Alto dos Passos - Juiz de Fora - MG
          </Text>
          <Text>
            Tel.: 32 3218-0707
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Footer;

