import React from 'react'

import { Center, 
  Image, 
  Heading, Flex, SimpleGrid } from "@chakra-ui/react"

import { Carousel } from 'react-responsive-carousel';
import img1 from '../../style/img/secondCarousel/1.jpg'
import img2 from '../../style/img/secondCarousel/2.jpg'
import img3 from '../../style/img/secondCarousel/3.jpg'
import img4 from '../../style/img/secondCarousel/4.jpg'
import img5 from '../../style/img/secondCarousel/5.jpg'
import img6 from '../../style/img/secondCarousel/6.jpg'
import BBQdrinks from '../../style/img/BBQ_DRINKS.png'

const Page2 = () => {

  return (
    <Center color='white' m='30px' p={['0', '0 5%', '0 10%', '0 15%']}>
      <Flex direction='column'>

        <Heading align='center' mb='5px' fontWeight='100'>EXPERIMENTE TAMBÉM A NOVIDADE:</Heading>

        <SimpleGrid columns={['1','1','2','2']}>
          <Center>
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            <div>
              <img src={img1} alt='imagem-1'/>
            </div>
            <div>
              <img src={img2} alt='imagem-2'/>
            </div>
            <div>
              <img src={img3} alt='imagem-3'/>
            </div>
            <div>
              <img src={img4} alt='imagem-4'/>
            </div>
            <div>
              <img src={img5} alt='imagem-5'/>
            </div>
            <div>
              <img src={img6} alt='imagem-6'/>
            </div>
          </Carousel>
          </Center>
          <Center bgColor='white' p='2px'>
            <Image src={BBQdrinks} alt="BBQ Drinks" />

          </Center>
        </SimpleGrid>
      </Flex>
    </Center>
  )
}

export default Page2