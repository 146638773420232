import React from 'react'

import { Center, Flex, Image, SimpleGrid } from "@chakra-ui/react"

import Form from '../Form'

import { Carousel } from 'react-responsive-carousel';
import img1 from '../../style/img/thirdCarousel/1.jpg'
import img2 from '../../style/img/thirdCarousel/2.jpg'
import img3 from '../../style/img/thirdCarousel/3.jpg'
import img4 from '../../style/img/thirdCarousel/4.jpg'
import vipClub from '../../style/img/vip_club.png'
import imgFormLink from '../../style/img/img_form_link.png'

const Page3 = (props) => {

  return (<>
    <SimpleGrid color='white' m='0 30px' p={['0', '0 5%', '0 10%', '0 15%']} columns={['1', '1', '2', '2']}>
      <Center>
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div>
            <img src={img1} alt='imagem-1' />
          </div>
          <div>
            <img src={img2} alt='imagem-2' />
          </div>
          <div>
            <img src={img3} alt='imagem-3' />
          </div>
          <div>
            <img src={img4} alt='imagem-4' />
          </div>
        </Carousel>
      </Center>
      <Center direction='column' bgColor='#ffac04'>
        <Flex p='2px' justify='space-evenly' >
          <Image src={vipClub} alt="VIP CLUB" boxSize="40%" alignSelf='center'/>
          <Image src={imgFormLink} alt="VIP CLUB" boxSize="50%" />
        </Flex>
      </Center>
    </SimpleGrid>
      <Form />
   
  </>
  )
}

export default Page3