import React from 'react'
import { ChakraProvider } from "@chakra-ui/react"
import Footer from "../../Components/Footer/Index"
import Header from "../../Components/Header"
import HeaderFixed from "../../Components/HeaderFixed/Index"
import Routes from '../../routes.js'

import Fonts from '../../style/font/index.js'
import theme from '../../style/theme/index.js'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Header />
      <HeaderFixed />
      <Routes />
      <Footer />
    </ChakraProvider>
  );
}

export default App;
