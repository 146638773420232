import React  from 'react'
import { Flex, Image, LinkBox, LinkOverlay} from "@chakra-ui/react"

import iMenu from '../../style/icon/menu.png'
import iInstagram from '../../style/icon/Instagram.png'
import iFacebook from '../../style/icon/Facebook.png'


import './HeaderFixed.css'

const HeaderFixed = () => {

  return (
    <div className='navbar'>
      <Flex w='100%'>
        <Flex bg="rgb(238,41,58)" w='100%' color='white' direction='row' align='center' p={['3% 0 3% 2.5%', '3% 0 3% 5%' ,'1% 0 1% 10%', '1% 0 1% 15%']}>
          <LinkBox as="div" display='flex' maxW="sm" rounded="md" >
            <Image boxSize="25px"
              src={iMenu}
              alt="Menu Digital"
              m='0 5px' />
            <LinkOverlay href='https://web.onemenu.com.br/churrasqueira-bbq' alignSelf='center' fontSize='17px'>
              Menu digital
            </LinkOverlay>
          </LinkBox>
        </Flex>
        <Flex w='125px' bg="#333333" direction='row' align='center' justify='space-evenly' >
          <LinkBox as="div" display='flex' maxW="sm" rounded="md" >
            <Image
              boxSize="25px"
              src={iInstagram}
              alt="Instagram"
            />
            <LinkOverlay href='https://instagram.com/churrasqueirabbq?utm_medium=copy_link' alignSelf='center' />
          </LinkBox>
          
          <LinkBox as="div" display='flex' maxW="sm" rounded="md" >
          <Image
              boxSize="25px"
              src={iFacebook}
              alt="Facebook"
              
            />
            <LinkOverlay href='https://www.facebook.com/102889694880523/' alignSelf='center' />
          </LinkBox>
        </Flex>
      </Flex>
    </div>
  )
}

export default HeaderFixed