import React from 'react';

import { Box } from "@chakra-ui/react"

import Page1 from "../Page1/Page1";
import Page2 from "../Page2/Page2";
import Page3 from "../Page3/Page3";

const Home = (props) => {
  return ( 
    <Box w='100' bg='#201c1c' textStyle="body">
        <Page1 />
        <Box w='100%' h='30px'/>
        <Page2 />
        <Box w='100%' h='30px'/>
        <Page3 />
        <Box w='100%' h='30px'></Box>
      </Box>
   );
}
 
export default Home;