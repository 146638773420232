import React from 'react';
import {
 Center,  FormControl,
  FormLabel,
   Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useForm, Controller } from "react-hook-form";

import firebase from '../../firebase/firebaseConnection'

const Form = (props) => {

  const { control, handleSubmit } = useForm();
  const toast = useToast()

  const onSubmit = async (data) => {
    let exists = false
    let currentdate = new Date(); 
    let datetime = currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " @ "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
    //console.log(data);

    await firebase.firestore().collection('VIP-CLUB')
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach(doc => {
          if (doc._delegate._document.data.value.mapValue.fields.Email.stringValue === data.email) // este email ja esta cadastado?
            exists = true
        })
      })
      .then(async () => {
        if (exists) { // este email ja esta cadastado?
          toast({
            position:'bottom',
            title: "Falha no cadastro.",
            description: "Email já cadastrado!",
            status: "error",
            duration: 9000,
            isClosable: true,
          })
        } else {  // cadastrar
          await firebase.firestore().collection('VIP-CLUB')
            .add({
              Nome: data.nome,
              Data_de_nascimento: data.date,
              Email: data.email,
              Telefone: data.telefone,
              Data_de_registro: datetime
            })
            .then(() => {
              toast({
                position:'bottom',
                title: "Cadastro efetuado.",
                description: "Parabéns você agora faz parte do nosso VIP CLUB.",
                status: "success",
                duration: 9000,
                isClosable: true,
              })
            })
            .catch((error) => {
              console.log(error)
              toast({
                position:'bottom',
                title: "Falha no cadastro.",
                description: "Tente novamente mais tarde.",
                status: "error",
                duration: 9000,
                isClosable: true,
              })
            })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (<>
    <Center bg='#201c1c' h="100%" color='white' p='2% 20%' mt='2'>
      <VStack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="VIP-CLUB">
          <FormLabel>Nome</FormLabel>
          <Controller
            name="nome"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type='text' {...field} required mb='5' />}
          />
          
          <FormLabel >Data de nascimento</FormLabel>
          <Controller
            name="date"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type='date' {...field} required mb='5' />}
          />

          <FormLabel>E-mail</FormLabel>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type='email' {...field} required mb='5'/>}
          />

          <FormLabel>Telefone</FormLabel>
          <Controller
            name="telefone"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type='text' {...field} required mb='5'/>}
          />

          <Input type="submit" mt='10px' value='Participar'/>
        </FormControl>
      </form>
      </VStack>
    </Center>
    </>);
}

export default Form;