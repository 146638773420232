import React, { useEffect, useState } from 'react'
import { Box, Button, Center, Heading, HStack, VStack } from "@chakra-ui/react"

import firebase from '../../firebase/firebaseConnection'

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react"

import {
  useJsonToCsv
} from 'react-json-csv';

const Admin = (props) => {

  const [dataT, setData] = useState([])
  const { saveAsCsv } = useJsonToCsv();

  useEffect(() => {
    const getData = async () => {
      let array = []
      await firebase.firestore().collection('VIP-CLUB')
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach(doc => {
            let obj = {}

            obj.email = doc._delegate._document.data.value.mapValue.fields.Email.stringValue
            obj.dt_nasc = doc._delegate._document.data.value.mapValue.fields.Data_de_nascimento.stringValue
            obj.nome = doc._delegate._document.data.value.mapValue.fields.Nome.stringValue
            obj.dt_reg = doc._delegate._document.data.value.mapValue.fields.Data_de_registro.stringValue
            obj.tel = doc._delegate._document.data.value.mapValue.fields.Telefone.stringValue

            array.push(obj)
          })
        })
        .catch((error) => {
          console.log(error)
        })
      setData(array)
    }

    getData()
  }, [])


  const downloadData = () => {
    const filename = 'VIP-CLUB'
    const fields = {
      "nome": "Nome",
      "email": "Email",
      "tel": 'Telefone',
      "dt_nasc": "Data de nascimento",
      "dt_reg": "Data de Registro"
    }
    const data = dataT;

    saveAsCsv({ data, fields, filename })
  }

  return (
    <Center color='white' bg='#201c1c'>
      <VStack mt='10'>
        
        <HStack w='100%' justifyContent='space-between'>
          <Heading>Painel VIP-CLUB</Heading>
          <Box>
            <Button onClick={() => downloadData()} color='green.400' mr='5'>Download</Button>
            <Button onClick={() => props.logout()} color='red.400'>Logout</Button>
          </Box>
        </HStack>
      

        <Box >
          {dataT.length > 0 &&
            <Table variant="striped" colorScheme="white" >
              <TableCaption>VIP CLUB - CHURRASQUEIRA BBQ</TableCaption>
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>email</Th>
                  <Th>Telefone</Th>
                  <Th>Data de nascimento</Th>
                  <Th>Data de Registro</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataT.map((dt, i) => {
                  return (
                    <Tr key={i}>
                      <Td>{dt.nome}</Td>
                      <Td>{dt.email}</Td>
                      <Td>{dt.tel}</Td>
                      <Td>{dt.dt_nasc}</Td>
                      <Td>{dt.dt_reg}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>-</Th>
                  <Th>-</Th>
                  <Th>-</Th>
                  <Th>-</Th>
                  <Th>-</Th>
                </Tr>
              </Tfoot>
            </Table>}
        </Box>
      </VStack>
    </Center>

  );
}

export default Admin;