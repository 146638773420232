import { Global } from "@emotion/react"

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @@font-face {
        font-family: "Barlow-Regular";
        src: url(./Barlow-Regular.ttf) format("truetype");
      `}
  />
)

export default Fonts

// url('./Gobold-Regular.woff') format('woff'), 
//              url('./Gobold-Regular.woff2') format('woff2'),
//              url('./Gobold-Regular.otf') format(otf),
//              url('./Gobold-Regular.ttf') format('ttf');