import React from 'react'

import {
  Text, 
  Center,
} from "@chakra-ui/react"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from 'react-responsive-carousel';
import img1 from '../../style/img/firstCarousel/1.jpg'
import img2 from '../../style/img/firstCarousel/2.jpg'
import img3 from '../../style/img/firstCarousel/3.jpg'
import img4 from '../../style/img/firstCarousel/4.jpg'
import img5 from '../../style/img/firstCarousel/5.jpg'
import img6 from '../../style/img/firstCarousel/6.png'
import img7 from '../../style/img/firstCarousel/7.png'
import img8 from '../../style/img/firstCarousel/8.png'
import img9 from '../../style/img/firstCarousel/9.png'
import img10 from '../../style/img/firstCarousel/10.png'



const Page1 = () => {

  return (
    <>
      <Center p={['0', '0 5%', '0 10%', '0 15%']}>
        <Carousel
        centerMode={true}
        centerSlidePercentage={90}
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          // showArrows={false}
        >
          <div>
            <img src={img1} alt='imagem-1' />
          </div>
          <div>
            <img src={img2} alt='imagem-2'/>
          </div>
          <div>
            <img src={img3} alt='imagem-3'/>
          </div>
          <div>
            <img src={img4} alt='imagem-4'/>
          </div>
          <div>
            <img src={img5} alt='imagem-5'/>
          </div>
          <div>
            <img src={img6} alt='imagem-6'/>
          </div>
          <div>
            <img src={img7} alt='imagem-7'/>
          </div>
          <div>
            <img src={img8} alt='imagem-8'/>
          </div>
          <div>
            <img src={img9} alt='imagem-9'/>
          </div>
          <div>
            <img src={img10} alt='imagem-10'/>
          </div>
        </Carousel>
      </Center>
      <Center position={['relative', 'relative', 'relative', 'absolute' ]} top={[ '','' ,'' ,'440px']} w="100%" color="white" p={['0', '0 5%', '0 10%', '0 15%']} >
        <Text align='center' mt='5' ml='50' w='60%' mr='50' fontSize='1.25rem' bgColor={['transparent','transparent' , 'transparent','rgb(0,0,0,0.5)']} p='10px' borderRadius='25px'>
          Restaurante especializado no estilo American BBQ.
          Deliciosas carnes angus, burgers, petiscos, drinks incríveis,
          música ambiente e a esquina mais charmosa de Juiz de Fora.
          <br/>
          <br/>
            Venha hoje mesmo viver essa experiência inesquecível!
          
        </Text>
      </Center>
    </>
  )
}

export default Page1