import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

let firebaseConfig = {
  apiKey: "AIzaSyAFqXLgrvPh8q4mrTdD2yglRf7TvLb_1ds",
  authDomain: "churrasqueira-bbq-23764.firebaseapp.com",
  projectId: "churrasqueira-bbq-23764",
  storageBucket: "churrasqueira-bbq-23764.appspot.com",
  messagingSenderId: "1011147089618",
  appId: "1:1011147089618:web:cdb6abe68f71020c226f0b",
  measurementId: "G-CM94HTK87D"
};

// Initialize Firebase
if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
}

export default firebase